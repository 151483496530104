import { RecaptchaVerifier } from 'firebase/auth';

let isLoaded = false;

const loadRecaptchaScript = () => {
  return new Promise((resolve, reject) => {
    if (isLoaded) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      isLoaded = true;
      resolve();
    };
    script.onerror = () => reject(new Error('Failed to load ReCAPTCHA script'));
    document.head.appendChild(script);
  });
};

export const initRecaptcha = async (auth, containerId) => {
  return loadRecaptchaScript().then(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, containerId, {
      'size': 'invisible',
      'callback': (response) => {
        return response;
      },
      'expired-callback': () => {},
    });

    return window.recaptchaVerifier;
  });
};
